import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { Footer } from "../components/Footer";
import { Nav } from "../components/Nav";
import { SEO } from "../components/SEO";
import { DateTime } from "luxon";
import { PostCard } from "../components/PostCard";

interface BlogPageProps {
	data: {
		metadata: Queries.WpSiteMetadataConnection;
		latest: Queries.WpPostConnection;
		categories: Queries.WpCategoryConnection;
	}
}

export default (props: BlogPageProps) => {
	return (<>
		<Nav navGroup={props.data.metadata.nodes[0].page_meta?.navLink!} />
		<section className="container py-5">
			<h3>Newest Posts</h3>
			<div className="row">
				{props.data.latest.nodes.map(node => <PostCard post={node} />)}
			</div>
		</section>
		{props.data.latest.nodes.length > 0 && (<section className="container pb-5">
			<h3>Our Most Popular Posts</h3>
			<div className="row">
				{props.data.latest.nodes.map(node => <PostCard post={node} />)}
			</div>
		</section>)}
		{props.data.categories.nodes.map(category => {
			if (category.posts?.nodes?.length === 0) {
				return null;
			}
			return (
				<section className="container pb-5">
					<h3>The Latest in {category.name}</h3>
					<div className="row">{category.posts?.nodes?.slice(0, 3).map(node => <PostCard post={node!} />)}</div>
					<div className="row mt-3">
						<Link to={`/blog/${category.slug}`} className="btn btn-outline-primary">Read More in {category.name}</Link>
					</div>
				</section>
			)
		})}
		<Footer navGroup={props.data.metadata.nodes[0].page_meta?.footer!.nav_links2!} />
	</>);
}

export const Head = (props: BlogPageProps) => {
	return <SEO title="Misty Blog" description="Read all the latest" pathname="/blogs" />
}

export const query = graphql`
{
	popular: allWpPost(sort: {order: ASC, fields: dateGmt}, limit: 3,  filter: {tags: {nodes: {elemMatch: {slug: {eq: "popular"}}}}}){
		...PostCardFragment
	}
	latest: allWpPost(sort: {order: ASC, fields: dateGmt}, limit:3) {
		...PostCardFragment
	}

	categories: allWpCategory(sort: {fields: posts___nodes___dateGmt, order: DESC}) {
			nodes {
				slug
				name
				posts {
						...PostCardCategoryFragment
				}
			}
		}

  metadata: allWpSiteMetadata(sort: {fields: modified}) {
    nodes {
      page_meta {
				footer {
					...LinkGroup
				}
				navLink {
					...LinkGroup
				}
      }
		}
	}
}
`